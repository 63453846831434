import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  pxToRem, breakpoint, spacing,
} from 'src/theme';
import Typography from 'src/components/core/Typography';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import ContactForm from 'src/components/ContactForm';
import Button from 'src/components/core/Button';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    height: 'auto',
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
    [breakpoint.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  textContainer: {
    maxWidth: '40%',
    paddingLeft: spacing(20),
    [breakpoint.down('md')]: {
      maxWidth: '100%',
      paddingLeft: 0,
      marginBottom: spacing(6),
    },
  },
  formContainer: {
    width: '50%',
    marginLeft: spacing(20),
    [breakpoint.down('md')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  textMarginBottom: {
    marginBottom: spacing(7),
    [breakpoint.down('md')]: {
      marginBottom: spacing(4),
    },
  },
});

interface Props {
  scrollToFAQHandler: () => void,
}

const ContactFormSection: React.FC<Props> = ({ scrollToFAQHandler }) => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container} background="light-blue">
      <NavbarSpacer />
      <div className={classes.content}>
        <div className={classes.textContainer}>
          <Typography variant="h2" color="blue" className={classes.textMarginBottom}>
            Whenever you need us, we are here.
          </Typography>
          <Typography variant="p1" color="blue" className={classes.textMarginBottom}>
            Looking for more information or want to try one of our paid Bizwise plans?
          </Typography>
          <Typography variant="p1" color="blue" className={classes.textMarginBottom}>
            Submit your information and a Bizwise representative will
            {' '}
            follow up with you as soon as possible.
          </Typography>
          <Typography variant="h3" color="blue">
            Have a simple question?
            <br />
            Check out our
            {' '}
            <Button
              variant="text"
              onClick={scrollToFAQHandler}
              color="teal"
              style={{ textDecoration: 'underline', padding: 0 }}
            >
              FAQ
            </Button>
            .
          </Typography>
        </div>
        <div className={classes.formContainer}>
          <ContactForm />
        </div>
      </div>
    </SectionContainer>
  );
};

export default ContactFormSection;

import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import ContactFormSection from 'src/sections/Contact/ContactFormSection';
import { useScrollableRef } from 'src/hooks';
import theme from 'src/theme';
import ApiWrapper from 'src/components/ApiWrapper';

const IndexPage: React.FC = () => {
  const { ref, handleScroll: handleScrollToFaq } = useScrollableRef();
  return (
    <ApiWrapper apiServices={['home-api']}>
      <Layout
        navbarProps={{
          backgroundCss: theme.palette.secondary.LIGHT_BLUE,
          darkText: true,
        }}
      >
        <Seo title="Contact Us - Bizwise" />
        <ContactFormSection scrollToFAQHandler={handleScrollToFaq} />
        <FaqSection ref={ref} types={[FaqTypes.PRICING, FaqTypes.FREE_FOREVER]} />
      </Layout>
    </ApiWrapper>
  );
};

export default IndexPage;
